@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Nunito:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&family=Roboto:wght@100;300;400;500;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');

body {
    margin: 0;
    padding: 0;
    font-family: "Roboto";
}

.container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.controllers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: auto;
    flex: 1;
    background-color: #ffffff;
    padding: 20px;
    padding-top: 30px;
    margin-top: 20px;
}

.combo {
    margin-bottom: 10px;
}

.group-title {
    color: #00A0EA;
    font-family: 'Roboto';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.023px;
    text-transform: uppercase;
    margin: 5px;
}

.heatmap {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 2vh;
    height: 100%;
    flex: 3;
    background-color: #ffffff;
    padding: 20px;
}

.csv {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}


select {
    border-radius: 2px;
    border: 1px solid #737A82;
    color: #737A82;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.filters {
    display: flex;
    flex-direction: column;
    width: 386px;
    height: fit-content;
    justify-content: space-around;
    margin-block: 20px;
}

.controllers label {
    color: #00A0EA;
    font-family: 'Roboto';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.018px;
    margin-left: 5px;
}

.filters * {
    width: 100%;
}

.filters button {
    width: 100%;
}

input,
select {
    height: 30px;
    border-radius: 2px;
    min-width: 386px;
    min-height: 41px;
    margin: 5px;
    border-radius: 2px;
    border: 1px solid #737A82;
}

button {
    height: 30px;
    border-radius: 2px;
    min-height: 41px;
    margin: 5px;
    border-radius: 2px;
    border: 1px solid #737A82;

}

.filters button {
    border: none;
    height: 30px;
    border-radius: 3px;
    background: #00A0EA;
    color: #FFFEFE;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.filters button:hover {
    background-color: #7aafdf;
}


canvas {
    display: block;
    margin: 0 auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.navbar {
    width: 100%;
    height: 25px;
    background-color: #FFF;
    color: #72b1ec;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo img {
    max-width: 100px;
}

#cameraButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

#cameraButton span:hover {
    color: #58B1DD;
}

#cameraButton span {
    width: 30px;
    height: auto;
}

.no-data {
    color: var(--neutral-gray-03, #C1C5C9);
    text-align: center;
    font-family: 'Poppins';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 200px;
}

.printscreen button span {
    margin-inline: 5px;
    display: inline;
    vertical-align: middle;
}

.printscreen button:hover {
    border-radius: 3px;
    background: #7aafdf;
}

.info {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9999;
    width: 400px;
    height: fit-content;
    border-radius: 5px;
    background: #1E1E1E;
    box-shadow: -4px 4px 20px 0px rgba(9, 49, 62, 0.20);
    padding: 20px 22px;
}

.info h1 {
    color: #FFF;
    font-family: 'Roboto';
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.info h1 span {
    margin-right: 16px;
    vertical-align: baseline;
}

.info p {
    color: #FFF;
    text-align: justify;
    font-family: 'Roboto';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.closeButton {
    position: absolute;
    top: 10px;
    /* Ajuste conforme necessário */
    right: 10px;
    /* Ajuste conforme necessário */
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: transparent;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
}

.cenpe-footer {
    color: var(--Neutral-Gray-04, #6C757D);
    text-align: right;
    font-family: 'Roboto';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: fixed;
    bottom: 20px;
    right: 50px;
}